<template>
  <!-- eslint-disable vue/max-attributes-per-line */ -->
  <!-- eslint-disable vue/html-indent -->
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-card>
          <b-card-header><b class="h1">Basic information</b></b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="12">
                <b-form-group label="*Promotion name:" label-cols-md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Promotion name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Promotion name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="*Promotion code:" label-cols-md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Promotion code"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.code"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Promotion code"
                    />
                    <small class="mt-1">
                      Promotion code must include only 5 - 15 characters and
                      numbers (no spaces).
                    </small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="*Discount promotion period:"
                  label-cols-md="3"
                >
                  <b-row>
                    <b-col md="6">
                      From - To
                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="model.date_from_to"
                          class="form-control"
                          :config="{ mode: 'range', dateFormat: 'Y/m/d' }"
                          placeholder="Choose a date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="*Discount type:" label-cols-md="3">
                  <b-row>
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        name="Is active"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <b-form-radio v-model="model.type" :value="1">
                            Amount
                          </b-form-radio>
                          <b-form-radio
                            v-model="model.type"
                            :value="2"
                            class="ml-3"
                          >
                            Percentage (%)
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col md="6" class="mt-1">
                      <validation-provider
                        #default="{ errors }"
                        name="Discount money"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.discount_money"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Active:" label-cols-md="3">
                  <b-form-checkbox
                    v-model="model.is_active"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Public the Coupon:" label-cols-md="3">
                  <b-form-checkbox
                    v-model="model.is_public"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card>
          <b-card-header><b class="h1">Basic information</b></b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="12">
                <b-form-group label="*Minimum Requirement:" label-cols-md="3">
                  <b-row>
                    <b-col md="6" class="mt-1">
                      <validation-provider
                        #default="{ errors }"
                        name="Minimum Requirement"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.min_total_price"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="*Total Number of Coupon:"
                  label-cols-md="3"
                >
                  <b-row>
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        name="Total Number of Coupon"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <b-form-radio
                            v-model="model.is_limit_number_coupon"
                            :value="0"
                          >
                            Unlimited
                          </b-form-radio>
                          <b-form-radio
                            v-model="model.is_limit_number_coupon"
                            :value="1"
                            class="ml-3"
                          >
                            Limit the number of coupon
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      v-if="model.is_limit_number_coupon === 1"
                      md="6"
                      class="mt-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Limit number coupon"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.limit_number_coupon"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="*Number of Usage per Customer:"
                  label-cols-md="3"
                >
                  <b-row>
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        name="Number of Usage per Customer"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <b-form-radio
                            v-model="model.is_number_usage_customer"
                            :value="0"
                          >
                            Unlimited
                          </b-form-radio>
                          <b-form-radio
                            v-model="model.is_number_usage_customer"
                            :value="1"
                            class="ml-3"
                          >
                            Number of Usage per Customer
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      v-if="model.is_number_usage_customer === 1"
                      md="6"
                      class="mt-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Number usage customer"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.number_usage_customer"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <!-- Submit -->
              <b-col md="12" class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BForm,
    BFormRadio,
    BFormCheckbox,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        name: null,
        code: null,
        is_active: true,
        type: 1,
        is_limit_number_coupon: 0,
        is_number_usage_customer: 0,
        date_from: null,
        date_to: null,
        date_from_to: null,
        discount_money: null,
        is_public: true,
        limit_number_coupon: null,
        min_total_price: null,
        number_usage_customer: null,
      },
      activeOptions: Config.activeOptions,
    }
  },
  methods: {
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        name: this.model.name,
        code: this.model.code,
        type: 1,
        discount_money: this.model.discount_money,
        is_public: Number(this.model.is_public === true ? 1 : 0),
        min_total_price: this.model.min_total_price,
        number_usage_customer: this.model.number_usage_customer,
        is_active: Number(this.model.is_active === true ? 1 : 0),
      }
      if (this.model.date_from_to !== null) {
        const dateFromTo = this.model.date_from_to.split(' to ')
        params.date_from = dateFromTo[0]
        params.date_to = dateFromTo[1]
      }
      if (this.model.is_limit_number_coupon === 1) {
        params.limit_number_coupon = this.model.limit_number_coupon
      } else {
        params.limit_number_coupon = 0
      }
      if (this.model.is_number_usage_customer === 1) {
        params.number_usage_customer = this.model.number_usage_customer
      } else {
        params.number_usage_customer = 0
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_coupon`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Coupon success',
                  },
                })
                this.$router.push('/coupon/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
