var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',[_c('b-card-header',[_c('b',{staticClass:"h1"},[_vm._v("Basic information")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Promotion name:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Promotion name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Promotion name"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Promotion code:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Promotion code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Promotion code"},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}}),_c('small',{staticClass:"mt-1"},[_vm._v(" Promotion code must include only 5 - 15 characters and numbers (no spaces). ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Discount promotion period:","label-cols-md":"3"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_vm._v(" From - To "),_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ mode: 'range', dateFormat: 'Y/m/d' },"placeholder":"Choose a date"},model:{value:(_vm.model.date_from_to),callback:function ($$v) {_vm.$set(_vm.model, "date_from_to", $$v)},expression:"model.date_from_to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Discount type:","label-cols-md":"3"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Is active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-radio',{attrs:{"value":1},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}},[_vm._v(" Amount ")]),_c('b-form-radio',{staticClass:"ml-3",attrs:{"value":2},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}},[_vm._v(" Percentage (%) ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Discount money","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.model.discount_money),callback:function ($$v) {_vm.$set(_vm.model, "discount_money", $$v)},expression:"model.discount_money"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Active:","label-cols-md":"3"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Public the Coupon:","label-cols-md":"3"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.model.is_public),callback:function ($$v) {_vm.$set(_vm.model, "is_public", $$v)},expression:"model.is_public"}})],1)],1)],1)],1)],1),_c('b-card',[_c('b-card-header',[_c('b',{staticClass:"h1"},[_vm._v("Basic information")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Minimum Requirement:","label-cols-md":"3"}},[_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Minimum Requirement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.model.min_total_price),callback:function ($$v) {_vm.$set(_vm.model, "min_total_price", $$v)},expression:"model.min_total_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Total Number of Coupon:","label-cols-md":"3"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Total Number of Coupon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-radio',{attrs:{"value":0},model:{value:(_vm.model.is_limit_number_coupon),callback:function ($$v) {_vm.$set(_vm.model, "is_limit_number_coupon", $$v)},expression:"model.is_limit_number_coupon"}},[_vm._v(" Unlimited ")]),_c('b-form-radio',{staticClass:"ml-3",attrs:{"value":1},model:{value:(_vm.model.is_limit_number_coupon),callback:function ($$v) {_vm.$set(_vm.model, "is_limit_number_coupon", $$v)},expression:"model.is_limit_number_coupon"}},[_vm._v(" Limit the number of coupon ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.model.is_limit_number_coupon === 1)?_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Limit number coupon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.model.limit_number_coupon),callback:function ($$v) {_vm.$set(_vm.model, "limit_number_coupon", $$v)},expression:"model.limit_number_coupon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2990843764)})],1):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Number of Usage per Customer:","label-cols-md":"3"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Number of Usage per Customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-radio',{attrs:{"value":0},model:{value:(_vm.model.is_number_usage_customer),callback:function ($$v) {_vm.$set(_vm.model, "is_number_usage_customer", $$v)},expression:"model.is_number_usage_customer"}},[_vm._v(" Unlimited ")]),_c('b-form-radio',{staticClass:"ml-3",attrs:{"value":1},model:{value:(_vm.model.is_number_usage_customer),callback:function ($$v) {_vm.$set(_vm.model, "is_number_usage_customer", $$v)},expression:"model.is_number_usage_customer"}},[_vm._v(" Number of Usage per Customer ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.model.is_number_usage_customer === 1)?_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Number usage customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.model.number_usage_customer),callback:function ($$v) {_vm.$set(_vm.model, "number_usage_customer", $$v)},expression:"model.number_usage_customer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,892737448)})],1):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"12"}},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }